import {
  OperationHistoryResponse,
  OperationResponse,
  pieChartData,
} from "../../interfaces/OperationsInterface";
import {
  OperationHistory,
  OperationState,
  MainCards,
  LineChartData,
} from "../../interfaces/OperationsInterface";

type OperationAction =
  | {
    type: "addOperation";
    payload: { success: boolean; message: string };
  }
  | {
    type: "deleteOperation";
  }
  | {
    type: "operationHistory";
    payload: { history: OperationHistoryResponse };
  }
  | {
    type: "getLineChartData";
    payload: { lineChartData: LineChartData[] };
  }
  | {
    type: "getPieChartData";
    payload: { pieChartData: pieChartData };
  }
  | {
    type: "getMainValue";
    payload: { mainCards: MainCards };
  };

export const OperationReducer = (
  state: OperationState,
  action: OperationAction
): OperationState => {
  switch (action.type) {
    case "addOperation":
      return {
        ...state,
        success: true,
        message: "",
      };

    case "operationHistory":
      return {
        ...state,
        history: action.payload.history,
      };

    case "getMainValue":
      return {
        ...state,
        mainCards: action.payload.mainCards,
      };

    case "deleteOperation":
      return {
        ...state,
      };

    case "getLineChartData":
      return {
        ...state,
        lineChartData: action.payload.lineChartData,
      };

    case "getPieChartData":
      return {
        ...state,
        pieChartData: action.payload.pieChartData,
      };
  }
};

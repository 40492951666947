import React from "react";
import useLogin from "../hooks/useLogin";
import Alert from "../components/Alert";
import { withRouter } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { GOOGLEAPIPUBLIC } from "../constants/GlobalConstants";
const Login = () => {
  const { loginGoogle } = useLogin();

  return (
    <>
      <div className="login-page container">
        <div className="form">
          <h2>Login</h2>

          <div className="login-button">
            <GoogleLogin
              clientId={GOOGLEAPIPUBLIC}
              buttonText="Iniciar sesión"
              onSuccess={loginGoogle}
              onFailure={loginGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </div>
      </div>
      <Alert />
    </>
  );
};

export default withRouter(Login);

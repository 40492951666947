import { useContext, useReducer, createContext } from "react";
import { useHistory } from "react-router-dom";
import coinApi from "../../api/CoinApi";
import { LOGIN } from "../../constants/GlobalConstants";
import {
  LineChartData,
  MainCards,
  OperationAdd,
  OperationHistory,
  OperationHistoryResponse,
  OperationResponse,
  OperationState,
  pieChartData,
} from "../../interfaces/OperationsInterface";
import { AuthContext } from "../auth/AuthContext";
import { OperationReducer } from "./OperationReducer";

type OperationContextProps = {
  history: OperationHistoryResponse;
  success: boolean;
  message: string;
  mainCards: MainCards;
  lineChartData: LineChartData[];
  pieChartData: pieChartData;
  addOperation: (data: OperationAdd) => void;
  deleteOperation: (id: number) => void;
  getHistory: (page?: number, category?: string) => void;
  getMainValues: () => void;
  getLineChartData: (categories: string) => void;
  getPieChartData: () => void;
};

const operationInitialState: OperationState = {
  history: {
    page: 1,
    result: [],
    total: 0,
  },
  success: true,
  message: "",
  lineChartData: [],
  pieChartData: {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      backgroundColor: [],
      hoverOffset: 4,
    }],
  },
  mainCards: {
    bank: 0,
    saved: 0,
    credit: 0,
    free: 0,
  },
};

export const OperationContext = createContext({} as OperationContextProps);

export const OperationProvider = ({ children }: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(OperationReducer, operationInitialState);
  const { logOut, token } = useContext(AuthContext);

  const addOperation = async ({
    description,
    value,
    category,
    type
  }: OperationAdd) => {
    try {
      let formData = new FormData();
      formData.append("value", value.toString());
      formData.append("token", token!);
      formData.append("description", description);
      formData.append("category", category.toString());
      formData.append("type", type.toString());

      const { data } = await coinApi.post<OperationResponse>(
        "/operations/operations/action/add",
        formData
      );

      //FALLA EL TOKEN
      if (!data.success) {
        logOut();
        history.push(LOGIN);
      }

      dispatch({
        type: "addOperation",
        payload: {
          success: data.success,
          message: data.message,
        },
      });

      //cards principales
      getMainValues();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteOperation = async (id: number) => {
    let formData = new FormData();
    formData.append("token", token!);
    formData.append("id", id.toString());

    const { data } = await coinApi.post<boolean>(
      "/operations/operations/delete",
      formData
    );

    //cards principales
    getMainValues();

    return data;
  };

  const getHistory = async (page = 1, category: string = "") => {
    let formData = new FormData();
    formData.append("token", token!);
    formData.append("category", category);
    formData.append("page", page.toString());

    const { data } = await coinApi.post<OperationHistoryResponse>(
      "/operations/operations/history",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "operationHistory",
      payload: {
        history: data,
      },
    });
  };

  const getMainValues = async () => {
    let formData = new FormData();
    formData.append("token", token!);

    const { data } = await coinApi.post<MainCards>(
      "/operations/operations/main_values",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "getMainValue",
      payload: {
        mainCards: data,
      },
    });
  };

  const getLineChartData = async (categories: string) => {
    let formData = new FormData();
    formData.append("token", token!);
    formData.append("categories", categories);
    const { data } = await coinApi.post<LineChartData[]>(
      "/operations/operations/data_line_chart",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "getLineChartData",
      payload: {
        lineChartData: data,
      },
    });
  };

  const getPieChartData = async () => {
    let formData = new FormData();
    formData.append("token", token!);
    const { data } = await coinApi.post<pieChartData>(
      "/operations/operations/data_pie_chart",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "getPieChartData",
      payload: {
        pieChartData: data,
      },
    });
  };

  return (
    <OperationContext.Provider
      value={{
        ...state,
        addOperation,
        getHistory,
        deleteOperation,
        getMainValues,
        getLineChartData,
        getPieChartData,
      }}
    >
      {children}
    </OperationContext.Provider>
  );
};

import React, { useContext, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  CREDIT,
  RETIREMENT,
  ENTRIES,
  SAVED,
} from "../constants/GlobalConstants";
import { OperationContext } from "../context/operation/OperationContext";
import { LineChartData } from "../interfaces/OperationsInterface";
import moment from "moment";
import "moment/locale/es";
import { AuthContext } from "../context/auth/AuthContext";
import useWindowSize from "../hooks/useWindowSize";

interface Dataset {
  label: string;
  data: number[];
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
  AxisID: string;
}

interface CategoryInitialData {
  id: number;
  name: string;
  color: string;
}

const options = {
  maintainAspectRatio: false,
  responsive: true,
  redraw: true,
  scales: {
    yAxes: [
      {
        type: "linear",
        display: false,
        position: "left",
        id: "axis-0",
      },
      {
        type: "linear",
        display: false,
        position: "left",
        id: "axis-1",
      },
    ],
  },
};

const buildData = (
  data: LineChartData[],
  categories: CategoryInitialData[]
) => {
  let labels: string[] = [];
  let datasets: Dataset[] = [];

  data.map((month, i) => {
    let m = moment(month.wrote).format("MMMM-YYYY");
    //revisa si ya existe en el array
    labels.indexOf(m) === -1 && labels.push(m);
  });

  categories.map(({ name, id, color }, i) => {
    let values: number[] = [];
    data.map((item) => {
      if (item.category === id.toString()) {
        values.push(parseInt(item.total));
      }
    });

    let content: Dataset = {
      label: name,
      data: values,
      fill: false,
      backgroundColor: color,
      borderColor: color,
      AxisID: `axis-${i}`,
    };

    datasets.push(content);
  });

  let toReturn = {
    labels,
    datasets,
  };

  return toReturn;
};

const aspectRatioCalculator = (windowWidth: number, width: number) => {
  let aspectRatio = {
    width,
    heigth: 0,
  };
  let constant = 1.777;
  let calc = width / constant;

  aspectRatio = { ...aspectRatio, heigth: calc };

  //responsive
  if (windowWidth < 500) {
    width = 300;
    calc = 400;

    aspectRatio = { ...aspectRatio, heigth: calc, width };
  }

  return aspectRatio;
};

const LineChart = () => {
  const { lineChartData, getLineChartData } = useContext(OperationContext);
  const { token } = useContext(AuthContext);
  const { currentWidth } = useWindowSize();
  const { width, heigth } = aspectRatioCalculator(currentWidth, 550);

  //categorias y colores
  let categories: CategoryInitialData[] = [
    {
      id: ENTRIES,
      name: "Entradas",
      color: "#289381",
    },
    {
      id: CREDIT,
      name: "Tarjeta de crédito",
      color: "#BABD53",
    },
    {
      id: RETIREMENT,
      name: "Retiro",
      color: "#a55e7d",
    },
  ];

  useEffect(() => {
    getLineChartData(`${CREDIT},${RETIREMENT},${ENTRIES}`);
  }, [token, currentWidth]);

  return (
    <Line
      type="line"
      width={width}
      height={heigth}
      data={buildData(lineChartData, categories)}
      options={options}
    />
  );
};

export default LineChart;

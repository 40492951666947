import React, { useContext } from "react";
import { OperationContext } from "../context/operation/OperationContext";

interface Props {
  category: number;
}
const Pagination = ({ category }: Props) => {
  const { getHistory, history } = useContext(OperationContext);

  //paginas a mostrar
  const spillsShowing = 5;

  //registros mostrando
  const rowsShowing = 10;

  //total de paginas
  const totalPages = Math.ceil(history.total / rowsShowing);

  let spills = [];

  for (let i = 1; i <= totalPages; i++) {
    spills.push({ page: i, active: false });
  }

  //esconder sobrantes
  spills.map((item) => {
    if (history.page - 2 <= 0) {
      if (item.page <= spillsShowing) {
        item.active = true;
      }
    } else if (history.page + 2 > totalPages) {
      if (item.page >= totalPages - 4) {
        item.active = true;
      }
    } else {
      if (history.page <= item.page + 2 && history.page >= item.page - 2) {
        item.active = true;
      }
    }
  });

  return (
    <div className="pagination-container">
      <div className="spills-container">
        {spills.map((item) => {
          if (item.active) {
            return (
              <button
                className={item.page == history.page ? "item active" : "item"}
                key={item.page}
                onClick={() => {
                  getHistory(item.page, category.toString());
                }}
              >
                {item.page}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Pagination;

import axios from "axios";

let baseURL = "http://localhost/coinwatcher-api/src";
const currentLocation = window.location.href;
const PRODUCCION = !currentLocation.includes(":3000");

if (PRODUCCION) {
  baseURL = "https://vps-0fe965c4.vps.ovh.ca";
} else {
  baseURL = "http://localhost/coinwatcher-api/src";
}

const coinApi = axios.create({ baseURL });

export default coinApi;

import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  HOME,
  LOGIN,
  OBLIGATIONS,
  OPERATIONS,
  REGISTER,
} from "../constants/GlobalConstants";
import { AuthContext } from "../context/auth/AuthContext";
import { ObligationContext } from "../context/obligation/ObligationContext";
import { OperationContext } from "../context/operation/OperationContext";
import Home from "../screens/Home";
import Login from "../screens/Login";
import Obligations from "../screens/Obligations";
import Operations from "../screens/Operations";
import Register from "../screens/Register";

const Navigator = () => {
  //  PRIMER CARGUE
  const { token } = useContext(AuthContext);
  const { obligationList, getTotalObligation } = useContext(ObligationContext);
  const { getMainValues } = useContext(OperationContext);

  useEffect(() => {
    if (!token) return;
    //listado de obligaciones
    obligationList();

    //total de obligaciones
    getTotalObligation();

    //cards principales
    getMainValues();
  }, [token]);

  return (
    <Router>
      <Switch>
        <Route path={HOME} component={Home} exact />
        <Route path={OPERATIONS} component={Operations} exact />
        <Route path={OBLIGATIONS} component={Obligations} exact />
        <Route path={LOGIN} component={Login} exact />
        <Route path={REGISTER} component={Register} exact />
      </Switch>
    </Router>
  );
};

export default Navigator;

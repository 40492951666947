import React from "react";
import { AlertProvider } from "./context/alert/AlertContext";
import { AuthProvider } from "./context/auth/AuthContext";
import { ObligationProvider } from "./context/obligation/ObligationContext";
import { OperationProvider } from "./context/operation/OperationContext";
import Navigator from "./navigator/Navigator";
import "./styles/css/Global.css";

const AppState = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <AuthProvider>
      <OperationProvider>
        <ObligationProvider>
          <AlertProvider>{children}</AlertProvider>
        </ObligationProvider>
      </OperationProvider>
    </AuthProvider>
  );
};

const App = () => (
  <AppState>
    <Navigator />
  </AppState>
);

export default App;

import { useContext, useReducer, createContext } from "react";
import { useHistory } from "react-router-dom";
import coinApi from "../../api/CoinApi";
import { LOGIN } from "../../constants/GlobalConstants";
import {
  ObligationAdd,
  ObligationList,
  ObligationResponse,
  ObligationState,
  TotalObligation,
} from "../../interfaces/ObligationsInterface";
import { AuthContext } from "../auth/AuthContext";
import { OperationContext } from "../operation/OperationContext";
import ObligationReducer from "./ObligationReducer";

type ObligationContextProps = {
  list: ObligationList[];
  success: boolean;
  message: string;
  totalObligation: string;
  addObligation: (data: ObligationAdd) => void;
  deleteObligation: (id: number) => void;
  setPaid: (id: number) => void;
  obligationList: () => void;
  getTotalObligation: () => void;
  deleteMsg: () => void;
};

const obligationInitialState: ObligationState = {
  list: [],
  success: true,
  message: "",
  totalObligation: "0",
};

export const ObligationContext = createContext({} as ObligationContextProps);

export const ObligationProvider = ({ children }: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(
    ObligationReducer,
    obligationInitialState
  );
  const { logOut, token } = useContext(AuthContext);
  const { getMainValues } = useContext(OperationContext);

  const addObligation = async ({ name, value, paid }: ObligationAdd) => {
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("paid", paid.toString());
      formData.append("value", value.toString());
      formData.append("token", token!);

      let { data } = await coinApi.post<ObligationResponse>(
        "/obligations/obligations/action/add",
        formData
      );

      //FALLA EL TOKEN
      if (!data.success) {
        logOut();
        history.push(LOGIN);
      }

      dispatch({
        type: "addObligation",
        payload: {
          success: data.success,
          message: data.message,
        },
      });
      getMainValues();
    } catch (error) {
      console.log(error);
    }
  };

  const setPaid = async (id: number) => {
    try {
      let formData = new FormData();
      formData.append("token", token!);
      formData.append("id", id.toString());

      let { data } = await coinApi.post<ObligationResponse>(
        "/obligations/obligations/set_paid",
        formData
      );

      //FALLA EL TOKEN
      if (typeof data === "string") {
        logOut();
        history.push(LOGIN);
      }

      dispatch({
        type: "setPaid",
        payload: {
          success: data.success,
          message: data.message,
        },
      });
      getMainValues();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteObligation = async (id: number) => {
    let formData = new FormData();
    formData.append("token", token!);
    formData.append("id", id.toString());

    const { data } = await coinApi.post<boolean>(
      "/obligations/obligations/delete",
      formData
    );
    getMainValues();

    return data;
  };

  const obligationList = async () => {
    let formData = new FormData();
    formData.append("token", token!);

    const { data } = await coinApi.post<ObligationList[]>(
      "/obligations/obligations/history",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "obligationList",
      payload: {
        list: data,
      },
    });
  };

  const deleteMsg = () => {
    dispatch({
      type: "deleteMsg",
    });
  };

  const getTotalObligation = async () => {
    let formData = new FormData();
    formData.append("token", token!);

    const { data } = await coinApi.post<TotalObligation>(
      "/obligations/obligations/total",
      formData
    );

    if (typeof data === "string") return;

    dispatch({
      type: "getTotalObligation",
      payload: {
        total: data.total,
      },
    });
  };

  return (
    <ObligationContext.Provider
      value={{
        ...state,
        addObligation,
        obligationList,
        deleteObligation,
        deleteMsg,
        setPaid,
        getTotalObligation,
      }}
    >
      {children}
    </ObligationContext.Provider>
  );
};

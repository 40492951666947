import React, { useContext, useEffect } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import MainCardValues from "../components/MainCardValues";
import { LOGIN, OPERATIONS } from "../constants/GlobalConstants";
import { AuthContext } from "../context/auth/AuthContext";
import Alert from "../components/Alert";
import WatchToken from "../components/WatchToken";
import OperationsForm from "../components/OperationsForm";
import ObligationsHome from "../components/ObligationsHome";
import { OperationContext } from "../context/operation/OperationContext";
import LineChart from "../components/LineChart";
import coinApi from "../api/CoinApi";
import { TotalObligation } from "../interfaces/ObligationsInterface";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PieChart from "../components/PieChart";

const Home = () => {
  const history = useHistory();
  const { logOut, token } = useContext(AuthContext);
  const {
    mainCards: { bank, free, credit, saved },
    getMainValues,
  } = useContext(OperationContext);
  WatchToken({ history });

  useEffect(() => {
    //revisar token
    watchTokenAvailable();

    getMainValues();
  }, []);

  const closeSection = () => {
    logOut();
    history.push(LOGIN);
  };

  const watchTokenAvailable = async () => {
    let formData = new FormData();
    let token = await AsyncStorage.getItem("token");

    formData.append("token", token!);

    const { data } = await coinApi.post<TotalObligation>(
      "/obligations/obligations/total",
      formData
    );

    // if (typeof data === "string") {
    //   closeSection();
    // }
  };

  return (
    <div className="container">
      <div className="first-column">
        <div className="operation-container">
          <OperationsForm />
          <NavLink to={OPERATIONS} className="card w-100 hoverable">
            Operaciones
          </NavLink>
          <button className="card w-100 hoverable red" onClick={closeSection}>
            Cerrar sesión
          </button>
        </div>
      </div>
      <div className="second-column">
        <div className="content">
          <div className="card-container">
            <MainCardValues name="Banco" value={bank} />
            <MainCardValues name="Ahorrado" value={saved} />
            <MainCardValues name="Libre" value={free} />
            <MainCardValues name="Tarjeta Crédito" value={credit} isCredit />
          </div>
          <ObligationsHome />
          <div className="chart-container">
            <LineChart />
          </div>
          <div className="pie-container">
            <PieChart />
          </div>
        </div>
      </div>
      <Alert />
    </div>
  );
};

export default withRouter(Home);

import { useReducer } from "react";
import { createContext } from "react";
import { Alert, AlertReducer } from "./AlertReducer";

type AlertContextProps = {
  message: string | null;
  show: boolean;
  showAlert: (data: Alert) => void;
  hideAlert: () => void;
};

const alertInitialState: Alert = {
  message: null,
  show: false,
};

export const AlertContext = createContext({} as AlertContextProps);

export const AlertProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AlertReducer, alertInitialState);

  const showAlert = (data: Alert) => {
    dispatch({
      type: "showAlert",
      payload: data,
    });
  };

  const hideAlert = () => {
    dispatch({
      type: "hideAlert",
    });
  };

  return (
    <AlertContext.Provider
      value={{
        ...state,
        showAlert,
        hideAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

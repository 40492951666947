import React, { useEffect, useState } from "react";

interface Props {
  show: boolean;
  message: string;
  action: (a: boolean) => void;
}
const Question = ({ show, message, action }: Props) => {
  const [Show, setShow] = useState(false);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return Show ? (
    <div className="alert-container">
      <div className="message-container">
        <h2>{message}</h2>
        <div className="btn-container">
          <button
            className="btn red"
            onClick={() => {
              action(false);
            }}
          >
            Regresar
          </button>
          <button
            className="btn"
            onClick={() => {
              action(true);
            }}
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Question;

export interface Alert {
  message: string | null;
  show: boolean;
}

type AlertAction =
  | { type: "showAlert"; payload: Alert }
  | { type: "hideAlert" };

export const AlertReducer = (state: Alert, action: AlertAction): Alert => {
  switch (action.type) {
    case "showAlert":
      return {
        ...state,
        message: action.payload.message,
        show: action.payload.show,
      };
    case "hideAlert":
      return {
        ...state,
        message: null,
        show: false,
      };

    default:
      return state;
  }
};

import React, { useContext, useEffect } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import Alert from "../components/Alert";
import WatchToken from "../components/WatchToken";
import { numberSeparator } from "../constants/GlobalFunctions";
import { AlertContext } from "../context/alert/AlertContext";
import { AuthContext } from "../context/auth/AuthContext";
import { ObligationContext } from "../context/obligation/ObligationContext";
import { useForm } from "../hooks/useForm";

const Obligations = () => {
  const history = useHistory();
  WatchToken({ history });

  const { showAlert } = useContext(AlertContext);
  const {
    message,
    deleteMsg,
    addObligation,
    list,
    deleteObligation,
    obligationList,
  } = useContext(ObligationContext);
  const { token } = useContext(AuthContext);
  const { name, value, paid, onChange, setFormValue } = useForm({
    name: "",
    value: "",
    paid: false,
  });

  useEffect(() => {
    obligationList();
  }, [token]);

  useEffect(() => {
    if (message.length > 0) {
      showAlert({
        message,
        show: true,
      });

      deleteMsg();
      obligationList();
    }
  }, [message]);

  const validateForm = () => {
    let ok = true;
    if (name.trim().length === 0 || value.trim().length === 0) {
      showAlert({
        message: "Se deben llenar todos los campos",
        show: true,
      });
      ok = false;
    }

    if (ok) {
      let goingValue = parseInt(value.replaceAll(".", ""));

      addObligation({
        name,
        paid,
        value: goingValue,
      });

      setFormValue({
        name: "",
        value: "",
        paid: false,
      });
    }
  };

  const removeObligation = async (id: number) => {
    let res = await deleteObligation(id);

    if (res!) {
      obligationList();
    }
  };

  return (
    <>
      <div className="container obligations-view">
        <div className="first-column">
          <div className="form">
            <h2>Obligación</h2>
            <input
              type="text"
              className="custom-input"
              placeholder="Escribir nombre"
              value={name}
              onChange={(e) => onChange(e.target.value, "name")}
            />

            <input
              type="text"
              className="custom-input"
              placeholder="Escribir Valor"
              value={value}
              onChange={(e) => {
                onChange(numberSeparator(e.target.value), "value");
              }}
            />

            <button
              className="btn w-100"
              onClick={() => {
                validateForm();
              }}
            >
              Agregar
            </button>
          </div>

          <NavLink to="/" className="card w-100 hoverable">
            Home
          </NavLink>
        </div>
        <div className="form-container">
          <div className="table">
            <div className="headline three">
              <div>Descripción</div>
              <div>Valor</div>
              <div>Acción</div>
            </div>
            {list.map((item, i) => {
              return (
                <div className="table-row three green" key={i}>
                  <div>{item.name}</div>
                  <div>$ {numberSeparator(item.value)}</div>
                  <div>
                    <button
                      className="btn red"
                      onClick={() => {
                        removeObligation(parseInt(item.id));
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Alert />
    </>
  );
};

export default withRouter(Obligations);

export const GOOGLEAPIPUBLIC =
  "22230353350-c00vpngispipj4g8vbot278gals2fvt7.apps.googleusercontent.com";
export const GOOGLEAPISECRET = "-9qHe6g81DcWQrT3dO8SaXv2";

export const HOME = "/";
export const OPERATIONS = "/operations/history";
export const OBLIGATIONS = "/obligations";
export const LOGIN = "/login";
export const REGISTER = "/register";

export const categories = [
  { value: 1, label: "Ahorro" },
  { value: 2, label: "Entrada" },
  { value: 3, label: "Retiro / PSE" },
  { value: 4, label: "Tarjeta Crédito" },
];

export const typeOperationsArray = [
  { value: 0, label: "Indefinido" },
  { value: 1, label: "Obligación" },
  { value: 2, label: "Ocio" },
  { value: 3, label: "Imprevisto" },
  { value: 4, label: "Entrada" },
]

export const SAVED = 1;
export const ENTRIES = 2;
export const RETIREMENT = 3;
export const CREDIT = 4;

export const customSelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#1a1f33",
    backgroundColor: state.isSelected ? "#232b42" : "white",
  }),
  control: () => ({
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "2px 0",
    margin: "7px 0",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    position: "absolute",
    top: 0,
    right: 0,
  }),

  singleValue: (provided: any, state: any) => {
    const opacity = 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

import React, { useContext, useEffect } from 'react'
import { Pie } from "react-chartjs-2";
import { OperationContext } from '../context/operation/OperationContext';
import { AuthContext } from '../context/auth/AuthContext';
import useWindowSize from '../hooks/useWindowSize';

const aspectRatioCalculator = (windowWidth: number, width: number) => {
    let aspectRatio = {
        width,
        heigth: 0,
    };
    let constant = 1.777;
    let calc = width / constant;

    aspectRatio = { ...aspectRatio, heigth: calc };

    //responsive
    if (windowWidth < 500) {
        width = 300;
        calc = 400;

        aspectRatio = { ...aspectRatio, heigth: calc, width };
    }

    return aspectRatio;
};
const PieChart = () => {
    const { token } = useContext(AuthContext);
    const { pieChartData, getPieChartData } = useContext(OperationContext);
    const { currentWidth } = useWindowSize();
    const { width, heigth } = aspectRatioCalculator(currentWidth, 350);

    useEffect(() => {
        getPieChartData()
    }, [token, currentWidth])



    return (
        <Pie type='pie'
            width={width}
            height={heigth}
            data={pieChartData} />
    )
}

export default PieChart
import React, { useEffect, useState } from "react";

const useWindowSize = () => {
  const [currentWidth, setcurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setcurrentWidth(window.innerWidth);
    });
  }, []);

  return {
    currentWidth,
  };
};

export default useWindowSize;

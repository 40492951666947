import { AuthState, RegisterResponse } from "../../interfaces/AuthInterfaces";

type AuthAction =
  | { type: "signIn"; payload: { token: string; user: string } }
  | { type: "addError"; payload: string }
  | { type: "removeError" }
  | { type: "nonAuthenticated" }
  | { type: "logOut" };

export const AuthReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "addError":
      return {
        ...state,
        user: null,
        status: "non-authenticated",
        token: null,
        message: action.payload,
      };
    case "removeError":
      return {
        ...state,
        message: "",
      };
    case "signIn":
      return {
        ...state,
        message: "",
        status: "authenticated",
        token: action.payload.token,
        user: action.payload.user,
      };

    case "nonAuthenticated":
    case "logOut":
      return {
        ...state,
        status: "non-authenticated",
        user: null,
        token: null,
      };

    default:
      return state;
  }
};

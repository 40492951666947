import { useContext, useEffect, useState } from "react";
import coinApi from "../api/CoinApi";
import { LOGIN } from "../constants/GlobalConstants";
import { AuthContext } from "../context/auth/AuthContext";
import { ObligationList } from "../interfaces/ObligationsInterface";

interface Props {
  history: any;
}
const WatchToken = ({ history }: Props) => {
  const [authenticated, setauthenticated] = useState(false);
  const { token, signInLS } = useContext(AuthContext);
  const [searched, setsearched] = useState(false);

  useEffect(() => {
    if (!token) {
      watchToken();
      setauthenticated(false);
    } else {
      setauthenticated(true);
    }
  }, [token]);

  useEffect(() => {
    if (searched && !token) {
      history.push(LOGIN);
    } else if (searched && token) {
      littleTest();
    }
  }, [searched]);

  const watchToken = async () => {
    await signInLS();
    setsearched(true);
  };

  const littleTest = async () => {
    let formData = new FormData();
    formData.append("token", token!);
    const { data } = await coinApi.post<ObligationList[] | string>(
      "/obligations/obligations/history",
      formData
    );

    if (typeof data === "string" && data.length === 5211) {
      history.push(LOGIN);
    }
  };

  return { authenticated, watchToken };
};

export default WatchToken;

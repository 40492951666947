import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import coinApi from "../api/CoinApi";
import { HOME } from "../constants/GlobalConstants";
import { AlertContext } from "../context/alert/AlertContext";
import { AuthContext } from "../context/auth/AuthContext";
import { GoogleResponse, RegisterResponse } from "../interfaces/AuthInterfaces";

const useLogin = () => {
  const history = useHistory();
  const { singIn, token } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      history.push(HOME);
    }
  }, [token]);

  const loginGoogle = async ({ profileObj }: any) => {
    let { email, familyName, givenName, googleId } = profileObj;

    let content = {
      firstname: givenName,
      first_lastname: familyName,
      username: email,
      email: email,
      password: googleId,
      password2: googleId,
      type: "2",
      status: "1",
    };

    let res = await coinApi.post<RegisterResponse>("/users/register", content);

    if (res.status === 200) {
      singIn({
        username: email,
        password: googleId,
      });
    }
  };

  return {
    loginGoogle,
  };
};

export default useLogin;

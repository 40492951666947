import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { categories, HOME } from "../constants/GlobalConstants";
import {
  findTypeOperationById,
  numberSeparator,
  showColorByCategory,
} from "../constants/GlobalFunctions";
import { AuthContext } from "../context/auth/AuthContext";
import { OperationContext } from "../context/operation/OperationContext";
import moment from "moment";
import "moment/locale/es";
import WatchToken from "../components/WatchToken";
import Pagination from "../components/Pagination";
import Question from "../components/Question";

const Operations = () => {
  const navigation = useHistory();
  const { token } = useContext(AuthContext);
  const { getHistory, history, deleteOperation } = useContext(OperationContext);
  const [category, setcategory] = useState(0);
  const [ShowQuestion, setShowQuestion] = useState(false);
  const [IdToDelete, setIdToDelete] = useState(0);

  WatchToken({ history: navigation });

  useEffect(() => {
    getHistory();
  }, [token]);

  const deleteOp = async (id: number) => {
    setShowQuestion(true);
    setIdToDelete(id);
  };

  const showMessage = async (response: boolean) => {
    if (response) {
      await deleteOperation(IdToDelete);
      getHistory();
    }

    setIdToDelete(0);
    setShowQuestion(false);
  };

  return (
    <>
      <div className="container">
        <div className="first-column">
          <NavLink to={HOME} className="card w-100 hoverable">
            Home
          </NavLink>
          <div className="form">
            <select
              className="custom-input"
              onChange={(e) => {
                setcategory(e.target.value ? parseInt(e.target.value) : 0);
                getHistory(1, e.target.value);
              }}
            >
              {categories.map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
            <button
              className="btn w-100"
              type="button"
              onClick={() => {
                setcategory(0);
                getHistory();
              }}
            >
              Limpiar
            </button>
          </div>
        </div>

        <div className="table">
          <div className="headline">
            <div>Descripción</div>
            <div>Valor</div>
            <div>Categoría</div>
            <div>Tipo</div>
            <div>Fecha</div>
            <div>Acción</div>
          </div>

          {history.result.map((item, i) => {
            let category = showColorByCategory(
              parseInt(item.category),
              parseInt(item.value)
            );
            return (
              <div
                className={!category.type ? "table-row green" : "table-row red"}
                key={i}
              >
                <div>{item.description}</div>
                <div>$ {numberSeparator(item.value)}</div>
                <div>{category.name}</div>
                <div>{findTypeOperationById(parseInt(item.type))}</div>
                <div>{moment(item.wrote).format("L")}</div>
                <div>
                  <button
                    className="btn red"
                    onClick={() => {
                      deleteOp(parseInt(item.id));
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            );
          })}

          <Pagination category={category} />
        </div>
      </div>
      <Question
        show={ShowQuestion}
        message="Esta seguro que desea eliminar esta operación?"
        action={showMessage}
      />
    </>
  );
};

export default Operations;

import React from "react";
import { useContext } from "react";
import {
  categories,
  CREDIT,
  customSelectStyles,
  RETIREMENT,
  typeOperationsArray,
} from "../constants/GlobalConstants";
import { numberSeparator } from "../constants/GlobalFunctions";
import { useForm } from "../hooks/useForm";
import { AlertContext } from "../context/alert/AlertContext";
import { OperationContext } from "../context/operation/OperationContext";
import Select from "react-select";

const OperationsForm = () => {
  const selectVal = [
    { value: 0, label: "Compra" },
    { value: 1, label: "Pago" },
  ];
  const { showAlert } = useContext(AlertContext);
  const { addOperation } = useContext(OperationContext);
  const { description, value, category, type, typeOperation, onChange, setFormValue } =
    useForm({
      description: "",
      value: "",
      category: 1,
      type: 0, //compra =0 y pago = 1,
      typeOperation: 0,
    });

  const validateForm = () => {
    let ok = true;
    if (description.trim().length === 0 || value.trim().length === 0) {
      showAlert({
        message: "Se deben llenar todos los campos",
        show: true,
      });
      ok = false;
    }

    if (ok) {
      let goingValue = parseInt(value.replaceAll(".", ""));


      //em caso de que sea pago de tarjeta tc
      if (type == 1 && category == CREDIT) {
        addOperation({
          description,
          category,
          value: parseInt(`-${goingValue}`),
          type: typeOperation
        });

        addOperation({
          description,
          category: RETIREMENT,
          value: goingValue,
          type: typeOperation
        });
      } else {
        //flujo normal
        addOperation({
          description,
          value: goingValue,
          category,
          type: typeOperation
        });
      }

      setFormValue({
        description: "",
        value: "",
        category: 1,
        type: 0,
        typeOperation: 0,
      });
    }
  };

  return (
    <div className="form">
      <h2>Operación</h2>
      <input
        className="custom-input"
        placeholder="Escribir descripción"
        onChange={(e) => {
          onChange(e.target.value, "description");
        }}
        value={description}
      />

      <input
        className="custom-input"
        placeholder="Escribir valor"
        value={value}
        onChange={(e) => {
          onChange(numberSeparator(e.target.value), "value");
        }}
      />

      <Select
        defaultValue={categories[0]}
        options={categories}
        styles={customSelectStyles}
        onChange={(e) => {
          onChange(e!.value.toString(), "category");
        }}
      />

      <Select
        defaultValue={typeOperationsArray[0]}
        options={typeOperationsArray}
        styles={customSelectStyles}
        onChange={(e) => {
          onChange(e!.value.toString(), "typeOperation");
        }}
      />

      {category == CREDIT && (
        <Select
          defaultValue={selectVal[0]}
          options={selectVal}
          styles={customSelectStyles}
          onChange={(e) => {
            onChange(e!.value.toString(), "type");
          }}
        />

      )}

      <button className="btn w-100" onClick={validateForm}>
        Enviar
      </button>
    </div>
  );
};

export default OperationsForm;

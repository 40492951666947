import React from "react";
import { numberSeparator } from "../constants/GlobalFunctions";
interface Props {
  value: number;
  name: string;
  isCredit?: boolean;
}
const MainCardValues = ({ value, name, isCredit = false }: Props) => {
  return (
    <div className="card">
      <p className={value > 0 && !isCredit ? "green" : "red"}>
        $ {numberSeparator(value.toString())}
      </p>
      <h3>{name}</h3>
    </div>
  );
};

export default MainCardValues;

import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { OBLIGATIONS } from "../constants/GlobalConstants";
import { numberSeparator } from "../constants/GlobalFunctions";
import { ObligationContext } from "../context/obligation/ObligationContext";

const ObligationsHome = () => {
  const {
    list,
    setPaid,
    message,
    obligationList,
    deleteMsg,
    getTotalObligation,
    totalObligation,
  } = useContext(ObligationContext);

  useEffect(() => {
    getTotalObligation();
    obligationList();
    deleteMsg();
  }, [message]);

  return (
    <div className="obligations-container">
      <h2>Obligaciones = $ {numberSeparator(totalObligation)}</h2>
      {list.map((item, i) => {
        return (
          <div
            className={
              item.paid === "1"
                ? "spill-container green"
                : "spill-container red"
            }
            key={i}
          >
            <div className="text-container">
              <p>$ {numberSeparator(item.value)}</p>
              <p className="spill-title">{item.name}</p>
            </div>
            <button
              className={item.paid === "1" ? "green-bg" : "red-bg"}
              onClick={() => {
                setPaid(parseInt(item.id));
              }}
            >
              {item.paid === "1" ? "Regresar" : "Pagar"}
            </button>
          </div>
        );
      })}
      <NavLink to={OBLIGATIONS} className="card w-100 hoverable">
        Administrar Obligaciones
      </NavLink>
    </div>
  );
};

export default ObligationsHome;

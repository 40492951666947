import {
  ObligationList,
  ObligationState,
} from "../../interfaces/ObligationsInterface";

type ObligationAction =
  | {
      type: "addObligation";
      payload: {
        success: boolean;
        message: string;
      };
    }
  | {
      type: "setPaid";
      payload: {
        success: boolean;
        message: string;
      };
    }
  | {
      type: "deleteMsg";
    }
  | {
      type: "deleteObligation";
    }
  | {
      type: "obligationList";
      payload: { list: ObligationList[] };
    }
  | {
      type: "getTotalObligation";
      payload: { total: string };
    };

const ObligationReducer = (
  state: ObligationState,
  action: ObligationAction
): ObligationState => {
  switch (action.type) {
    case "setPaid":
    case "addObligation":
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
      };

    case "obligationList":
      return {
        ...state,
        list: action.payload.list,
      };

    case "deleteObligation":
      return {
        ...state,
      };

    case "getTotalObligation":
      return {
        ...state,
        totalObligation: action.payload.total,
      };

    case "deleteMsg":
      return {
        ...state,
        success: true,
        message: "",
      };
  }
};

export default ObligationReducer;

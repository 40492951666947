import React, { useContext, useState, useEffect } from "react";
import { AlertContext } from "../context/alert/AlertContext";

const Alert = () => {
  const { show, message, hideAlert } = useContext(AlertContext);
  const [Show, setShow] = useState(false);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return Show ? (
    <div className="alert-container">
      <div className="message-container">
        <h2>{message}</h2>
        <button className="btn" onClick={hideAlert}>
          Ok
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Alert;

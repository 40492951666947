import { useReducer, createContext } from "react";
import coinApi from "../../api/CoinApi";
import {
  AuthState,
  LoginData,
  LoginResponse,
} from "../../interfaces/AuthInterfaces";
import { AuthReducer } from "./AuthReducer";
import AsyncStorage from "@react-native-async-storage/async-storage";

type AuthContextProps = {
  message: string;
  token: string | null;
  user: string | null;
  status: "checking" | "authenticated" | "non-authenticated";
  singIn: (data: LoginData) => void;
  signInLS: () => void;
  logOut: () => void;
  removeError: () => void;
};

const authInitialState: AuthState = {
  status: "checking",
  token: null,
  user: null,
  message: "",
  registerResponse: null,
};

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AuthReducer, authInitialState);

  //LOGIN
  const singIn = async ({ username, password }: LoginData) => {
    try {
      const { data } = await coinApi.post<LoginResponse>("/users/login", {
        username,
        password,
      });

      if (data.auth) {
        dispatch({
          type: "signIn",
          payload: {
            token: data.token,
            user: data.user,
          },
        });

        await AsyncStorage.setItem("token", data.token);
        await AsyncStorage.setItem("user", data.user);
      } else {
        dispatch({
          type: "addError",
          payload: data.message,
        });
      }
    } catch (error) {
      dispatch({
        type: "addError",
        payload: error.response.data.message || "info incorrecta",
      });
    }
  };

  //LOGIN DESDE LOCALSTORAGE
  const signInLS = async () => {
    let token = await AsyncStorage.getItem("token");
    let user = await AsyncStorage.getItem("user");

    if (token && user) {
      dispatch({
        type: "signIn",
        payload: {
          token,
          user,
        },
      });
    } else {
      dispatch({
        type: "logOut",
      });
    }
  };

  const logOut = async () => {
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("user");

    dispatch({
      type: "logOut",
    });
  };

  const removeError = () => {
    dispatch({
      type: "removeError",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        singIn,
        logOut,
        signInLS,
        removeError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

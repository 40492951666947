import { categories, typeOperationsArray } from "./GlobalConstants";

export const numberSeparator = (n: string) => {
  if (!n) return "";

  n = n.split(".").join("");
  n = n.split(",").join("");

  let lastChar = n.substring(n.length - 1, n.length);
  if (lastChar === "-") {
    n = n;
  } else if (!isFinite(parseInt(lastChar))) {
    n = n.substring(0, n.length - 1);
  }

  while (true) {
    let n2 = n.replace(/(\d)(\d{3})($|,|\.)/g, "$1.$2$3");
    if (n === n2) break;
    n = n2;
  }

  return n;
};

export const showColorByCategory = (id: number, value: number) => {
  let category = categories.filter((item) => id === item.value)[0];
  let retirement = true;
  if ((id === categories[0].value && value > 0) || id === categories[1].value) {
    retirement = false;
  }
  let data = {
    name: category.label,
    type: retirement,
  };

  return data;
};

export const findTypeOperationById = (id: number) => {
  let res = typeOperationsArray.find((type) => type.value === id);

  return res ? res.label : "Indefinido";
}
